@use './variables' as _media;

html,
body {
    -webkit-font-smoothing: antialiased;
    height: 100%;
    width: 100%;
}

body,
body * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    color: var(--color-black);
    font-family: var(--font-main);
    font-size: var(--default-size);
    font-weight: 300;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

ol {
    margin: 0;
    padding: 0 0 0 20px;
    //list-style-position: inside;
}

a {
    color: inherit;
    text-decoration: none;
}

button {
    background-color: inherit;
    border: none;
    color: inherit;
    cursor: pointer;
    outline: none;
    padding: 0;
}

input {
    background-color: inherit;
    border: none;
    font-size: inherit;
    outline: none;
    padding: 0;

    &[type='search']::-webkit-search-decoration,
    &[type='search']::-webkit-search-cancel-button,
    &[type='search']::-webkit-search-results-button,
    &[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
}

textarea,
select {
    -webkit-appearance: none;
    background-color: inherit;
    border: none;
    font-size: inherit;
    outline: none;
    padding: 0;
}

select,
label {
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: normal;
    line-height: normal;
    margin: 0;
}

p {
    font-size: inherit;
    margin: 0;
}

figure {
    line-height: 0;
    margin: 0;
}

img {
    display: inline-block;
    max-width: 100%;
}

p {
    //line-height: 20px;
    //line-height: 1.3;
    //line-height: 28px;
    line-height: 1.6;

    &:not(:last-of-type) {
        margin-bottom: 14px;

        @media screen and (min-width: _media.$start-media-table) {
            margin-bottom: 20px;
        }
    }
}

svg {
    max-height: 100%;
    max-width: 100%;
}
