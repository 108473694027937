@use '../../assets/scss/variables' as _media;

.container {
    $this: 'content';

    display: flex;
    //margin-top: var(--height-header);
    min-height: calc(100vh - var(--height-header));
    //min-height: calc(100vh - 100vh / 50);
    //overflow: hidden;
    position: relative;
    //align-items: center;

    &--home {
        background-color: var(--bg-home);
        background-image: url('../../assets/img/main-bg-m.png');
        background-position: 0 -140px;
        background-repeat: no-repeat;
        background-size: contain;

        @media screen and (min-width: _media.$start-media-table) {
            background-image: url('../../assets/img/main-bg.png');
            background-position: top;
            background-size: inherit;
        }
    }

    &__child {
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        /*    &--second {
          margin: calc(var(--height-header) + var(--m-vertical)) var(--m-horizont) var(--m-vertical);
        }

        &:not(.container__child--second) {
          .content-wrap {
            padding-top: 50px;
            padding-bottom: 50px;
          }
        } */
    }
}
