:root {
    // FONTS ------------------------
    --font-main: 'e-Ukraine', sans-serif;

    // COLORS ------------------------
    --color-white: #fff;
    --color-black: #000;
    --color-gray-h: #666;
    --color-gray-m: #9c9c9c;
    --color-gray-l: #a1a1a1;
    --color-invalid: #ff3800;
    --color-icon-card: #b3b3b3;
    --color-gray-main: #959595;
    --bg-home: #151e2e;
    --bg-border-second: #e6ebe6;
    --bg-gray: #f5f6fa;
    --bg-white: #fff;
    --bg-black: #000;
    --bg-invalid: #ffc3c3;
    --bg-blue: #5b5aff;
    --bg-cat: #eaeaee;
    --color-blue: #5b5aff;
    --color-border: #b3b3b3;
    --color-gray: #ccc;

    // ANIMATION ------------------------
    --anim-fast: 0.15s cubic-bezier(0, 0, 0.3, 1);
    --anim-slow: 0.25s cubic-bezier(0, 0, 0.3, 1);

    // MARGINS ------------------------
    --m-outside: 16px;
    --m-inside: 16px;

    // SIZE -----------------------
    --max-w: 1460px;

    //FONT-SIZE ----------------------
    --main-title: 26px;
    --second-title: 18px;
    --default-size: 14px;
    --text-P1: 26px;
    --text-P2: 18px;
    --text-P3: 18px;
    --text-P4: 16px;
    --text-P5: 14px;
    --text-P6: 12px;
    --height-header: 82px;
    --shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
    --opacity-hover: 0.8;
}

@media screen and (min-width: 768px) {
    :root {
        //FONT-SIZE ----------------------
        --main-title: 48px;
        --second-title: 36px;
        --default-size: 16px;
        --text-P1: 48px;
        --text-P2: 36px;
        --text-P3: 24px;
        --text-P4: 18px;
        --text-P5: 16px;
        --text-P6: 14px;

        // MARGINS ------------------------
        --m-outside: 40px;
        --m-inside: 20px;
        --height-header: 100px;
    }
}

/* @media screen and (max-width: 340px) {
  :root {
    //FONT-SIZE ----------------------
    --main-title: 34px;
    --second-title: 34px;
    --default-size: 18px;

    // MARGINS ------------------------
  }
} */

//SCSS VARIABLE
$start-media-desktop: 1024px;
$start-media-table: 768px;
$end-media-table: 1023px;
$end-media-mobile: 767px;
$end-media-mini-mob: 330px;
