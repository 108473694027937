@use '../../assets/scss/variables' as _media;

.footer {
    background-color: var(--color-white);
    border-top: 1px solid var(--bg-border-second);
    display: flex;
    padding: 21px 0;

    &__second {
        margin-top: 46px;

        @media screen and (min-width: _media.$start-media-table) {
            margin-top: 80px;
        }
    }

    &__container {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: center;

        @media screen and (max-width: _media.$end-media-mobile) {
            flex-wrap: wrap;
        }
    }

    .main-logo {
        flex: 1;
        margin-right: 20px;
        max-width: 250px;
        @media screen and (min-width: _media.$start-media-table) {
            max-width: 482px;
        }

        @media screen and (max-width: _media.$end-media-mobile) {
            order: 1;
        }
    }
}
