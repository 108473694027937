@use '../../assets/scss/variables' as _media;

.form {
    display: flex;
    //flex-wrap: nowrap;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    //min-height: 400px;

    &__field-wrap {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-bottom: 20px;
        position: relative;
        width: 100%;

        @media screen and (min-width: _media.$start-media-table) {
            margin-bottom: 26px;
        }

        &--hidden {
            opacity: 0;
            position: absolute;
            z-index: -1;
        }
    }

    &__field {
        background-color: var(--color-white);
        border: 1px solid var(--border-input);
        border-radius: 5px;
        box-shadow: var(--shadow);
        color: var(--dark-blue);
        padding: 18px var(--m-inside);
        width: 100%;

        &--invalid {
            background-color: var(--bg-invalid);
        }
    }

    &__links {
        margin-top: 26px;
    }

    &__link {
        color: var(--color-links);
        font-size: 18px;
        text-decoration: underline;

        &:hover {
            opacity: var(--opacity-hover);
        }
    }

    &__label {
        cursor: default;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.6;
        margin-bottom: 15px;

        @media screen and (min-width: _media.$start-media-table) {
            //font-size: var(--text-P5);
        }

        &-require {
            color: var(--color-invalid);
            font-size: 14px;
            padding-left: 8px;
        }
    }

    &__sub-label {
        color: #959595;
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 20px;
    }

    &__require {
        color: var(--red-require);
    }

    &__invalid {
        &-block {
            color: var(--color-invalid);
            margin-top: 20px;

            @media screen and (min-width: _media.$start-media-table) {
                margin-top: 24px;
            }
        }

        &-icon {
            height: 17px;
            margin-bottom: -3px;
            margin-left: 5px;
            width: 21px;
        }
    }

    &__error {
        color: var(--color-invalid);
        display: inline-block;
        font-size: var(--text-P6);
        margin-left: 8px;
        margin-top: 8px;

        @media screen and (min-width: _media.$start-media-table) {
            font-size: var(--text-P5);
        }
    }

    &__bad-request {
        color: var(--color-invalid);
        display: inline-block;
        font-size: var(--text-P5);
        line-height: 20px;
        margin: 30px auto 0;
        max-width: 316px;
        text-align: center;

        span {
            color: inherit;
            font-size: inherit;
        }
    }
}

form {
    & > .btn {
        margin-left: auto;
        margin-right: auto;
        max-width: initial;
    }

    .react-datepicker__input-container {
        input {
            background-color: #f6f6fa;
            border: none;
            border-radius: 0;
            font-size: inherit;
            height: auto;
            outline: none;
            padding: 18px;
        }
    }

    .react-datepicker__triangle {
        display: none;
    }
}

.field-file {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-items: center;
    text-align: center;

    @media screen and (min-width: 768px) {
        flex-direction: column-reverse;
        position: absolute;
        right: 0;
        z-index: 1;
    }

    &__square {
        background-color: var(--color-white);
        border: 2px solid #cdcdd3;
        border-radius: 2px;
        cursor: pointer;
        height: 146px;
        margin-bottom: 10px;
        padding: 10px;
        width: 146px;

        @media screen and (min-width: 1024px) {
            &:hover {
                opacity: 0.8;
            }
        }

        /*    @media screen and (min-width: 768px) {
      width: 356px;
      height: 284px;
    } */

        &__resume {
            background-color: var(--color-white);
            height: 40px;
            padding: 10px;
            width: 120px;

            @media screen and (min-width: 768px) {
                height: 70px;
                width: 356px;
            }
        }
    }

    &__icon {
        &-wrap {
            //border: 1px solid;
            align-items: center;
            display: flex;
            flex: 1;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            width: 100%;

            &--invalid {
                border-color: red;
            }
        }

        color: #cdcdd3;
        font-size: 40px;

        &--invalid {
            color: red;
        }

        &-text {
            display: none;
            font-weight: bold;
            text-align: center;

            @media screen and (min-width: 768px) {
                display: block;
            }
        }
    }

    &__text {
        cursor: default;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.6;
        margin-bottom: 15px;
    }
}
