@use '../../assets/scss/variables' as _media;

.header {
    background-color: var(--color-white);
    display: flex;
    height: var(--height-header);
    width: 100%;

    @media screen and (min-width: _media.$start-media-table) {
        border-bottom: 1px solid #cdcdd3;
    }

    &__container {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: space-between;
    }

    &__wrap-btn {
        display: none;
        @media screen and (min-width: _media.$start-media-table) {
            display: block;
            max-width: 224px;
            width: 100%;
        }
    }

    .main-logo {
        flex: 1;
        margin-right: 28px;
        max-width: 250px;
        @media screen and (min-width: _media.$start-media-table) {
            max-width: 482px;
        }
    }

    .btn {
        @media screen and (max-width: _media.$end-media-mobile) {
            height: 48px;
            min-width: auto;
            padding: 0;
            width: 48px;

            &_text {
                display: none;
            }
        }

        @media screen and (min-width: _media.$start-media-table) {
            .icon {
                display: none;
            }
        }
    }
}
