@use '../../assets/scss/variables' as _media;

.loader-is {
    display: inline-block;
    height: 100px;
    margin: 0 auto 1em;
    padding: 1em;
    text-align: center;
    vertical-align: top;
    width: 100%;

    @media screen and (min-width: _media.$start-media-table) {
    }
}
