@use 'variables' as c;

.m-width {
    margin-left: auto;
    margin-right: auto;
    //max-width: calc(var(--max-w) + var(--m-outside) * 2);
    max-width: var(--max-w);
    padding-left: var(--m-outside);
    padding-right: var(--m-outside);
    width: 100%;
}

h1 {
    font-size: var(--main-title);
}

h2 {
    font-size: var(--second-title);
}

.loading {
    align-items: center;
    display: flex;
    flex: 1;
    height: 100vh;
    justify-content: center;
    width: 100vw;
    //background-color: var(--bg-light-blue);
}

.container-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 850px;
}

[class^='icon-']::before,
[class*=' icon-']::before {
    margin: auto;
    width: auto;
}

.ul-custom {
    list-style: circle;
    margin-bottom: 20px;
    padding-left: 22px;
}

.container-content {
    b {
        font-weight: 500;
        line-height: 1.6;
    }

    a {
        color: var(--color-blue);
        text-decoration: underline;

        &:hover {
            opacity: var(--opacity-hover);
        }
    }
}

.elements {
    margin-bottom: 34px;
    margin-top: 18px;
}

.error__text {
    color: red;
}

.only-this-horizon-scrollbar {
    overflow: auto;
    white-space: nowrap;

    @media screen and (min-width: c.$start-media-desktop) {
        &::-webkit-scrollbar {
            height: 5px;
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
}
